import React, { useState } from "react";
import {
  Row,
  Col,
  Layout,
  Upload,
  Modal,
  Typography,
  Button,
  message,
} from "antd";
import HeaderWithSteps from "../common/HeaderWithSteps";
import { UploadFile } from "antd/lib/upload/interface";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { BASIS_ID_FILE_UPLOAD_URL } from "../../constants";
import { IUser } from "../../rootRouter";

interface Props {
  user: IUser;
  setIDPhotos(
    sideOne: string,
    sideOneRaw: UploadFile<any>,
    sideTwo: string,
    sideTwoRaw: UploadFile<any>
  ): void;
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const IDUpload = (props: Props) => {
  const { push } = useHistory();

  const [sideOne, setSideOne] = useState<UploadFile<any>[]>(
    props.user.raw_IDPhotoSideOne ? [props.user.raw_IDPhotoSideOne] : []
  );
  const [sideOnePath, setSideOnePath] = useState<string>(
    props.user.IDPhotoSideOne ? props.user.IDPhotoSideOne : ""
  );
  const [sideTwo, setSideTwo] = useState<UploadFile<any>[]>(
    props.user.raw_IDPhotoSideTwo ? [props.user.raw_IDPhotoSideTwo] : []
  );
  const [sideTwoPath, setSideTwoPath] = useState<string>(
    props.user.IDPhotoSideTwo ? props.user.IDPhotoSideTwo : ""
  );
  const [previewModal, setPreviewModal] = useState<any>({
    previewImage: "",
    previewTitle: "",
    previewVisible: false,
  });

  let handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewModal({
      previewImage: file.preview,
      previewVisible: true,
      previewTitle: file.name,
    });
  };

  const uploadButton = (
    <div>
      <FaPlus />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewModal({ previewVisible: false });

  const handleContinue = () => {
    props.setIDPhotos(sideOnePath, sideOne[0], sideTwoPath, sideTwo[0]);

    push("/signup/address");
  };

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={2} showBack />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24} className="text-center">
              <Typography.Title
                level={3}
                className="text-center typography-title mt-3 mb-0 secondary"
              >
                Upload both sides of your identification document.
              </Typography.Title>
              <br />
              <div className="text-center" style={{ fontSize: 14 }}>
                Make sure that the document isn't cropped and that it is clear
                and easily readable.
              </div>
            </Col>
          </Row>
          <Row justify="center" align="top" className="text-center">
            <Col>
              <Row justify="center" className="pr-2">
                <b>Side One</b>
              </Row>
              <Row>
                <Upload
                  action={BASIS_ID_FILE_UPLOAD_URL}
                  listType="picture-card"
                  fileList={sideOne}
                  onPreview={handlePreview}
                  onChange={async ({ file, fileList }) => {
                    setSideOne(fileList);
                    if (
                      file.status === "done" &&
                      file.response &&
                      file.response.success === true
                    ) {
                      setSideOnePath(file.response.filepath);
                    } else if (file.status === "error") {
                      message.error("File upload failed.");
                    }
                  }}
                >
                  {sideOne.length >= 1 ? null : uploadButton}
                </Upload>
              </Row>
              {sideOne.length === 1 && (
                <Row justify="center">
                  <Button
                    type="ghost"
                    shape="circle"
                    size="large"
                    onClick={() => {
                      setSideOne([]);
                      setSideOnePath("");
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </Row>
              )}
            </Col>
            <Col>
              <Row justify="center" className="pr-2">
                <b>Side Two</b>
              </Row>
              <Row>
                <Upload
                  action={BASIS_ID_FILE_UPLOAD_URL}
                  listType="picture-card"
                  fileList={sideTwo}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => {
                    setSideTwo(fileList);
                    if (
                      file.status === "done" &&
                      file.response.success === true
                    ) {
                      setSideTwoPath(file.response.filepath);
                    } else if (file.status === "error") {
                      message.error("File upload failed.");
                    }
                  }}
                >
                  {sideTwo.length >= 1 ? null : uploadButton}
                </Upload>
              </Row>
              {sideTwo.length === 1 && (
                <Row justify="center">
                  <Button
                    type="ghost"
                    shape="circle"
                    size="large"
                    onClick={() => {
                      setSideTwo([]);
                      setSideTwoPath("");
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </Row>
              )}
            </Col>
            <Modal
              visible={previewModal.previewVisible}
              title={previewModal.previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={previewModal.previewImage}
              />
            </Modal>
          </Row>
          <Row className="mt-8 pt-8">
            <Button
              type="primary"
              block
              size="large"
              disabled={sideOnePath === "" || sideTwoPath === ""}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default IDUpload;
