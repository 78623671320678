import React from "react";
import { Row, Col } from "antd";
import logo from "../../assets/Student_Card_logo.svg";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface Props {
  currentStep: number;
  showBack?: boolean;
}

const HeaderWithSteps = (props: Props) => {
  const { goBack } = useHistory();
  return (
    <div>
      <Row justify="center" align="top" className="mt-4 mb-2">
        <Col xs={24}>
          {props.showBack && (
            <div className="mb-2 text-primary arrow-back">
              <div onClick={() => goBack()}>
                <FaArrowLeft />
              </div>
            </div>
          )}
          <div className="text-center" style={{ fontSize: 14 }}>
            <span
              className={"p-1" + (props.currentStep === 1 ? " secondary" : "")}
            >
              Contact
            </span>
            /
            <span
              className={"p-1" + (props.currentStep === 2 ? " secondary" : "")}
            >
              Identity
            </span>
            /
            <span
              className={"p-1" + (props.currentStep === 3 ? " secondary" : "")}
            >
              Address
            </span>
          </div>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <img src={logo} width="320" height="60" alt="Student Card Logo" />
      </Row>
    </div>
  );
};

export default HeaderWithSteps;
