import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Typography, Layout, Input } from "antd";
import { Store } from "antd/lib/form/interface";
import { AiOutlineMail, AiOutlineMessage } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import HeaderWithSteps from "../common/HeaderWithSteps";

interface Props {
  setPhoneAndEmailVerified(): void;
}

const ContactVerification = (props: Props) => {
  const { replace } = useHistory();
  const [verificationForm] = Form.useForm();
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let timeout: any = null;
    if (countdown === 0) {
      replace("/signup");
    } else {
      timeout = setTimeout(() => setCountdown(countdown - 1), 60000);
    }
    return timeout !== null
      ? () => {
          clearTimeout(timeout);
        }
      : () => {};
    // eslint-disable-next-line
  }, [countdown]);

  const onVerify = (values: Store) => {
    props.setPhoneAndEmailVerified();
    replace("/signup/consent");
  };

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={1} showBack />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24} className="text-center">
              <Typography.Title
                level={3}
                className="text-center typography-title mt-3 mb-0 secondary"
              >
                Check your email inbox and SMS for confirmation codes.
              </Typography.Title>
              <br />
              <div className="text-center" style={{ fontSize: 14 }}>
                The codes we sent will be valid for {countdown} minutes.
              </div>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col xs={24}>
              <Form
                name="verification_form"
                form={verificationForm}
                onFinish={onVerify}
              >
                <Form.Item
                  name="emailCode"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email verification code.",
                    },
                  ]}
                >
                  <Input
                    prefix={<AiOutlineMail className="site-form-item-icon" />}
                    placeholder="Email verification code"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="SMSCode"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please input your SMS verification code.",
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <AiOutlineMessage className="site-form-item-icon" />
                    }
                    placeholder="SMS verification code"
                    size="large"
                  />
                </Form.Item>

                <Form.Item shouldUpdate={true} className="mb-2">
                  {() => (
                    <Button
                      type="primary"
                      block
                      size="large"
                      htmlType="submit"
                      disabled={
                        !verificationForm.isFieldsTouched(
                          ["emailCode", "SMSCode"],
                          true
                        ) ||
                        verificationForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length > 0
                      }
                    >
                      Continue
                    </Button>
                  )}
                </Form.Item>

                <div className="text-secondary text-right">
                  Having trouble?
                  <Button type="link" style={{ padding: 4, fontWeight: 600 }}>
                    Resend code
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default ContactVerification;
