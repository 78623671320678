const useDev = process.env.NODE_ENV === "development";

// export const SAVE_CARD_URL =
//   (useDev ? "http://127.0.0.1:31337" : "") + "/api/saveCard";

// export const CREATE_PAYMENT_INTENT_FOR_EXISTING_CARD_URL =
//   (useDev ? "http://127.0.0.1:31337" : "") +
//   "/api/paymentIntentForExistingCard";

// export const CREATE_PAYMENT_INTENT_CARD_URL =
//   (useDev ? "http://127.0.0.1:31337" : "") + "/api/paymentIntent";

const localIP = "http://192.168.100.49:31337";

export const GET_BASIS_ID_SIGNATURE_URL =
  (useDev ? localIP : "") + "/api/getBasisIDSignature";

export const BASIS_ID_REGISTRATION_URL =
  (useDev ? localIP : "") + "/api/sendBasisIDData";

export const BASIS_ID_FILE_UPLOAD_URL =
  (useDev ? localIP : "") + "/api/uploadFile";

export const BASISID_PUB_KEY = "prod-liQCbayexNvRrubkzYtYeuQQYGqkfExL";
