import React from "react";
import { Select } from "antd";
import { FaRegFlag } from "react-icons/fa";
import { countries } from "../../countries";
import ReactCountryFlag from "react-country-flag";

interface Props {
  placeholder?: React.ReactNode;
}

// you can override the props on Select
class CountrySelect extends React.Component<Props> {
  render() {
    return (
      <Select
        className="select-has-image-prefix pb-0_5"
        showSearch
        optionLabelProp="label"
        filterOption={(input, option) => {
          return (
            option?.children.props.children[2].props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) > -1
          );
        }}
        placeholder={
          <React.Fragment>
            <FaRegFlag className="site-form-item-icon" /> Country of citizenship
          </React.Fragment>
        }
        size="large"
        {...this.props}
      >
        {countries.map((v, i) => (
          <Select.Option
            key={i}
            value={v.alpha2code}
            label={
              <div>
                <ReactCountryFlag
                  countryCode={v.alpha2code}
                  svg
                  className="antd-select-prefix-image"
                />
                &nbsp;&nbsp;<span>{v.name}</span>
              </div>
            }
          >
            <div>
              <ReactCountryFlag
                countryCode={v.alpha2code}
                svg
                className="antd-select-prefix-image"
              />
              &nbsp;&nbsp;<span>{v.name}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default CountrySelect;
