import React from "react";
import ReactDOM from "react-dom";
import RootRouter from "./rootRouter";
import { BrowserRouter as Router } from "react-router-dom";

// We are using react-icons: https://react-icons.github.io/react-icons/
// basisid widget: https://www.basisid.com/ross.consulting/
ReactDOM.render(
  <Router>
    {/* <Elements stripe={stripePromise}> */}
    <RootRouter />
    {/* </Elements> */}
  </Router>,
  document.getElementById("root")
);
