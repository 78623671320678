import React from "react";
import { Button, Form, Row, Col, Layout, Checkbox } from "antd";
import { Store } from "antd/lib/form/interface";
import { useHistory } from "react-router-dom";
import HeaderWithSteps from "../common/HeaderWithSteps";

interface Props {
  setConsentGiven(): void;
}

const DataCollectionConsent = (props: Props) => {
  const { push } = useHistory();
  const [consentForm] = Form.useForm();

  const onConsent = (values: Store) => {
    props.setConsentGiven();
    push("/signup/profile");
  };

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={2} showBack />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24}>
              <div className="text-center">
                In order to comply with the USA Patriot Act provisions, Federal
                law requires US financial institutions to verify the following:
              </div>
              <br />
              <div>
                <ul>
                  <li>Name, Address, Date of Birth</li>
                  <li>Drivers license and/or other identifying documents</li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col xs={24}>
              <Form name="consent_form" form={consentForm} onFinish={onConsent}>
                <Form.Item
                  name="consent"
                  className="pb-0_5"
                  valuePropName="checked"
                >
                  <Checkbox>
                    I agree to provide this information truthfully
                  </Checkbox>
                </Form.Item>
                <Form.Item shouldUpdate={true} className="mb-2">
                  {() => (
                    <Button
                      type="primary"
                      block
                      size="large"
                      htmlType="submit"
                      disabled={!consentForm.getFieldValue("consent")}
                    >
                      Continue
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default DataCollectionConsent;
