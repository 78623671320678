import React, { useState } from "react";
import {
  Row,
  Col,
  Layout,
  Upload,
  Modal,
  Typography,
  Button,
  message,
} from "antd";
import HeaderWithSteps from "../common/HeaderWithSteps";
import { UploadFile } from "antd/lib/upload/interface";
import { FaPlus } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { BASIS_ID_FILE_UPLOAD_URL } from "../../constants";

interface Props {
  setProofOfAddressPhoto(proof: string): void;
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const AddressUpload = (props: Props) => {
  const [proofPhoto, setProofPhoto] = useState<UploadFile<any>[]>([]);
  const [proofPhotoPath, setProofPhotoPath] = useState<string>("");

  const [previewModal, setPreviewModal] = useState<any>({
    previewImage: "",
    previewTitle: "",
    previewVisible: false,
  });

  let handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewModal({
      previewImage: file.preview,
      previewVisible: true,
      previewTitle: file.name,
    });
  };

  const uploadButton = (
    <div>
      <FaPlus />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewModal({ previewVisible: false });

  const handleContinue = () => {
    console.log("here");
    props.setProofOfAddressPhoto(proofPhotoPath);
  };

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={3} showBack />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24} className="text-center">
              <Typography.Title
                level={3}
                className="text-center typography-title mt-3 mb-0 secondary"
              >
                Upload utility bill.
              </Typography.Title>
              <br />
              <div className="text-center" style={{ fontSize: 14 }}>
                Make sure that the information is easily readable.
              </div>
            </Col>
          </Row>

          <Row justify="center" align="top" className="text-center">
            <Col>
              <Row>
                <Upload
                  action={BASIS_ID_FILE_UPLOAD_URL}
                  listType="picture-card"
                  fileList={proofPhoto}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => {
                    setProofPhoto(fileList);
                    if (
                      file.status === "done" &&
                      file.response.success === true
                    ) {
                      setProofPhotoPath(file.response.filepath);
                    } else if (file.status === "error") {
                      message.error("File upload failed.");
                    }
                  }}
                >
                  {proofPhoto.length >= 1 ? null : uploadButton}
                </Upload>
              </Row>
              {proofPhoto.length === 1 && (
                <Row justify="center">
                  <Button
                    type="ghost"
                    shape="circle"
                    size="large"
                    onClick={() => {
                      setProofPhoto([]);
                      setProofPhotoPath("");
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </Row>
              )}
            </Col>
            <Modal
              visible={previewModal.previewVisible}
              title={previewModal.previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={previewModal.previewImage}
              />
            </Modal>
          </Row>
          <Row className="mt-8 pt-8">
            <Button
              type="primary"
              block
              size="large"
              disabled={proofPhoto.length !== 1}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default AddressUpload;
