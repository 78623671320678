import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Typography,
  Layout,
  Input,
  message,
} from "antd";
import { Store } from "antd/lib/form/interface";
import { useHistory } from "react-router-dom";
import HeaderWithSteps from "../common/HeaderWithSteps";
import CountrySelect from "../common/countrySelect";
import { FaRegFlag } from "react-icons/fa";
import { IProfile, IAddress } from "../../rootRouter";
import { countries } from "../../countries";

interface Props {
  profile: IProfile;
  updateAddress(address: IAddress): void;
}

const AddressInput = (props: Props) => {
  const [addressForm] = Form.useForm();
  const history = useHistory();

  const onFinish = (values: Store) => {
    props.updateAddress({
      country: values.country,
      city: values.city,
      streetName: values.streetName,
      postalCode: values.postalCode,
    });
    history.push("/signup/addressUpload");
  };

  const getCurrentLocation = () => {
    fetch("https://extreme-ip-lookup.com/json") // Get the IP data
      .then((res) => res.json())
      .then((ip) => {
        if (ip.country) {
          const matchedCountry = countries.find(
            (v) => v.alpha2code === ip.countryCode
          );
          if (matchedCountry) {
            addressForm.setFieldsValue({
              country: matchedCountry.alpha2code,
              city: ip.city,
            });
          }
        }
      });
  };

  // To disable submit button at the beginning.
  const [, forceUpdate] = useState();
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={3} showBack />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24} className="text-center">
              <Typography.Title
                level={3}
                className="text-center typography-title mt-3 mb-0 text-primary"
              >
                Address
              </Typography.Title>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col xs={24}>
              <Form
                name="addressForm"
                form={addressForm}
                onFinish={onFinish}
                onFinishFailed={() => {
                  message.error("Please complete the form first.");
                }}
                initialValues={{
                  ...props.profile.address,
                  country:
                    props.profile.address?.country || props.profile.nationality,
                }}
              >
                <Form.Item
                  name="country"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please select a country of residence.",
                    },
                  ]}
                >
                  <CountrySelect
                    placeholder={
                      <React.Fragment>
                        <FaRegFlag className="site-form-item-icon" /> Country of
                        residence
                      </React.Fragment>
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="city"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please input your city.",
                    },
                  ]}
                >
                  <Input placeholder="City" size="large" />
                </Form.Item>
                <Form.Item
                  name="streetName"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please input your street name.",
                    },
                  ]}
                >
                  <Input placeholder="Street name" size="large" />
                </Form.Item>
                <Form.Item
                  name="postalCode"
                  className="pb-0_5"
                  rules={[
                    {
                      required: true,
                      message: "Please input your postal code.",
                    },
                  ]}
                >
                  <Input placeholder="Postal code" size="large" />
                </Form.Item>

                <Button
                  type="primary"
                  ghost
                  block
                  size="large"
                  className="mb-4"
                  onClick={() => getCurrentLocation()}
                >
                  Use current location
                </Button>

                <Form.Item shouldUpdate={true}>
                  {() => (
                    <Button
                      type="primary"
                      block
                      size="large"
                      htmlType="submit"
                      disabled={
                        addressForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length > 0).length >
                          0 ||
                        (!props.profile.address &&
                          !addressForm.isFieldsTouched(
                            ["city", "streetName", "postalCode"],
                            true
                          ))
                      }
                    >
                      Continue
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default AddressInput;
