import React, { Component } from "react";
import { Input, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";

import "./simpleDatePicker.css";

interface Props {
  yearName: string;
  monthName: string;
  dateName: string;
  form: FormInstance;
  validDates?(current: moment.Moment): boolean;
  requiredErrorMessage?: string;
  invalidDateRangeErrorMessage?: string;
}

// interface State {
//   monthInput: any;
//   dayInput: any;
// }

export default class SimpleDatePicker extends Component<Props> {
  monthInput: React.RefObject<Input>;
  dayInput: React.RefObject<Input>;
  constructor(props: any) {
    super(props);
    this.monthInput = React.createRef();
    this.dayInput = React.createRef();
  }
  render() {
    const { yearName, monthName, dateName } = this.props;

    const revalidate = (
      flag: boolean,
      length?: number,
      ref?: React.RefObject<Input>
    ) => async (e: any) => {
      if (ref && e.target.value.length === length) {
        ref.current?.focus();
        ref.current?.select();
      }
      if (
        flag &&
        (this.props.form.isFieldTouched([this.props.dateName]) ||
          this.props.form.getFieldValue([this.props.dateName]) !== undefined)
      ) {
        try {
          await this.props.form.validateFields([this.props.dateName]);
        } catch (e) {}
      }
    };

    return (
      <Input.Group compact className="simple-date-picker" {...this.props}>
        <Form.Item noStyle name={yearName}>
          <Input
            placeholder="YYYY"
            className="w-40"
            type="number"
            onChange={revalidate(true, 4, this.monthInput)}
          />
        </Form.Item>
        <Form.Item noStyle name={monthName}>
          <Input
            placeholder="MM"
            className="w-30"
            type="number"
            onChange={revalidate(true, 2, this.dayInput)}
            ref={this.monthInput}
          />
        </Form.Item>
        <Form.Item
          noStyle
          name={dateName}
          rules={[
            {
              validator: (r, day) => {
                return new Promise((resolve, reject) => {
                  let month = this.props.form.getFieldValue(monthName);
                  let year = this.props.form.getFieldValue(yearName);
                  if (
                    year === undefined ||
                    year === "" ||
                    month === undefined ||
                    month === "" ||
                    day === undefined ||
                    day === ""
                  ) {
                    if (this.props.requiredErrorMessage) {
                      reject(this.props.requiredErrorMessage);
                    } else {
                      resolve();
                    }
                  } else {
                    let date = moment(
                      year + "-" + month + "-" + day,
                      ["YYYY-MM-DD", "YYYY-M-DD", "YYYY-MM-D", "YYYY-M-D"],
                      true
                    );
                    if (date.isValid()) {
                      if (this.props.validDates) {
                        if (this.props.validDates(date)) {
                          resolve();
                        } else {
                          reject(
                            this.props.invalidDateRangeErrorMessage ||
                              "Invalid date"
                          );
                        }
                      } else {
                        resolve();
                      }
                    } else {
                      reject("Invalid date");
                    }
                  }
                });
              },
            },
          ]}
        >
          <Input
            placeholder="DD"
            className="w-30"
            type="number"
            onChange={revalidate(true)}
            ref={this.dayInput}
          />
        </Form.Item>
      </Input.Group>
    );
  }
}
