import React from "react";
import { Row, Col, Typography, Layout, Spin } from "antd";
import HeaderWithSteps from "../common/HeaderWithSteps";
import { IUser } from "../../rootRouter";
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";

interface Props {
  user: IUser;
}

const VerificationStatus = (props: Props) => {
  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={4} />
          <Row justify="center" align="top" className="mb-6">
            <Col xs={24} className="text-center">
              <Typography.Title
                level={3}
                className="text-center typography-title mt-3 mb-0 secondary"
              >
                {props.user.verificationStatus === "processing" ||
                props.user.verificationStatus === "unavailable" ? (
                  <>
                    Please wait a moment while we validate your documents.
                    <br />
                    <br />
                    <Spin size="large" className="bg-primary" />
                  </>
                ) : props.user.verificationStatus === "approved" ? (
                  `Verification complete`
                ) : (
                  `Verification issue`
                )}
              </Typography.Title>
              <br />
              <div className="text-center">
                {props.user.verificationStatus === "processing" ||
                props.user.verificationStatus === "unavailable" ? (
                  <div>
                    This page will refresh once verification is complete. This
                    process can take a few minutes.
                    <br />
                    <br />
                    You will also receive an email and SMS with details on the
                    next steps to activate your card.
                    <br />
                    <br />
                    {props.user.submittedTimestamp ? (
                      <>Submitted on {props.user.submittedTimestamp}</>
                    ) : (
                      ""
                    )}
                    <br />
                  </div>
                ) : (
                  <div>
                    <Typography.Paragraph className="text-center typography-text">
                      {props.user.verificationStatus === "rejected"
                        ? `Unfortunately your application has been rejected during the verification process. If you feel this was an error, please contact support on support@studentcard.app.`
                        : `Congratulations! You have passed the verification process. You will receive an email and SMS with details on the next steps to activate your card.`}
                      <br />
                      <br />
                      {`Your application has been ${props.user.verificationStatus} on`}
                      <br />
                      {`${props.user.verificationTimestamp}.`}
                      {props.user.verificationStatus === "approved" ? (
                        <AiOutlineCheckCircle className="ml-1 success" />
                      ) : (
                        <AiOutlineExclamationCircle className="ml-1 error" />
                      )}
                    </Typography.Paragraph>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default VerificationStatus;
