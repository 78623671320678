import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Layout, Input, message, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import { useHistory } from "react-router-dom";
import HeaderWithSteps from "../common/HeaderWithSteps";
import moment from "moment";
import { IProfile } from "../../rootRouter";
import SimpleDatePicker from "../common/simpleDatePicker";

interface Props {
  profile: IProfile;
  updateProfile(profile: IProfile): void;
}

const ProfileDetailsInput = (props: Props) => {
  const [profileForm] = Form.useForm();
  const { push } = useHistory();

  const onFinish = (values: Store) => {
    // console.log(values);
    let identityDocumentExpiryDate = moment(
      values.identityExpiry_year +
        "-" +
        values.identityExpiry_month +
        "-" +
        values.identityExpiry_day,
      ["YYYY-MM-DD", "YYYY-M-DD", "YYYY-MM-D", "YYYY-M-D"],
      true
    );
    props.updateProfile({
      ...props.profile,
      lastName: values.lastName,
      firstName: values.firstName,
      middleNames: values.middleNames,
      dateOfBirth: moment(
        values.dob_year + "-" + values.dob_month + "-" + values.dob_day,
        ["YYYY-MM-DD", "YYYY-M-DD", "YYYY-MM-D", "YYYY-M-D"],
        true
      ).format("YYYY-MM-DD"),
      gender: values.gender,
      identityNumber: values.identityNumber,
      identityDocumentExpiryDate: identityDocumentExpiryDate.isValid()
        ? identityDocumentExpiryDate.format("YYYY-MM-DD")
        : undefined,
    });
    push("/signup/idUpload");
  };

  // To disable submit button at the beginning.
  const [, forceUpdate] = useState();
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Layout.Content className="p-4 h-100">
      <Row justify="center" align="top">
        <Col xs={24} sm={16} md={10} lg={7} xl={5}>
          <HeaderWithSteps currentStep={2} showBack />

          <Row justify="center" align="middle">
            <Col xs={24}>
              <Form
                className="custom-form"
                labelCol={{ span: 10 }}
                labelAlign="left"
                name="profileForm"
                form={profileForm}
                size="middle"
                onFinish={onFinish}
                onFinishFailed={() => {
                  message.error("Please complete the form first.");
                }}
                layout="vertical"
                initialValues={
                  props.profile
                    ? {
                        ...props.profile,
                        dob_year: props.profile.dateOfBirth?.substring(0, 4),
                        dob_month: props.profile.dateOfBirth?.substring(5, 7),
                        dob_day: props.profile.dateOfBirth?.substring(8, 10),
                        identityExpiry_year: props.profile.identityDocumentExpiryDate?.substring(
                          0,
                          4
                        ),
                        identityExpiry_month: props.profile.identityDocumentExpiryDate?.substring(
                          5,
                          7
                        ),
                        identityExpiry_day: props.profile.identityDocumentExpiryDate?.substring(
                          8,
                          10
                        ),
                      }
                    : {}
                }
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name.",
                    },
                  ]}
                >
                  <Input placeholder="First name" />
                </Form.Item>
                <Form.Item
                  name="middleNames"
                  label="Middle Names"
                  rules={[
                    {
                      required: false,
                      message: "Please input your middle names (if any).",
                    },
                  ]}
                >
                  <Input placeholder="Middle names" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name.",
                    },
                  ]}
                >
                  <Input placeholder="Last name" />
                </Form.Item>

                <Form.Item label="Date of Birth" required>
                  <SimpleDatePicker
                    yearName="dob_year"
                    monthName="dob_month"
                    dateName="dob_day"
                    validDates={(current) => current < moment().startOf("day")}
                    form={profileForm}
                    invalidDateRangeErrorMessage="Please input a valid date of birth."
                    requiredErrorMessage="Please input a date of birth."
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please input your gender.",
                    },
                  ]}
                >
                  <Select placeholder="Gender">
                    <Select.Option value={"male"}>Male</Select.Option>
                    <Select.Option value={"Female"}>Female</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="identityNumber"
                  label="Identity Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your identity number.",
                    },
                  ]}
                >
                  <Input placeholder="Identity number" size="middle" />
                </Form.Item>
                <Form.Item label="ID Document Expiry">
                  <SimpleDatePicker
                    yearName="identityExpiry_year"
                    monthName="identityExpiry_month"
                    dateName="identityExpiry_day"
                    validDates={(current) => current > moment().endOf("day")}
                    form={profileForm}
                    invalidDateRangeErrorMessage="Document has already expired."
                  />
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                  {() => (
                    <Button
                      type="primary"
                      block
                      size="large"
                      htmlType="submit"
                      disabled={
                        profileForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length > 0).length >
                          0 ||
                        (!props.profile.dateOfBirth &&
                          !profileForm.isFieldsTouched(
                            [
                              "lastName",
                              "firstName",
                              "dob_year",
                              "dob_day",
                              "dob_month",
                              "gender",
                              "identityNumber",
                            ],
                            true
                          ))
                      }
                    >
                      Continue
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default ProfileDetailsInput;
