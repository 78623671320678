import React, { Suspense, useState, useEffect } from "react";
import { Layout, Col, Row, message } from "antd";
import moment from "moment";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import "./app.scss";
import "./utils.scss";
import logo from "./assets/Student_Card_logo.svg";
import ContactVerification from "./components/authentication/contactVerification";
import DataCollectionConsent from "./components/authentication/dataCollectionConsent";
import ProfileDetailsInput from "./components/authentication/profileDetailsInput";
import {
  BASISID_PUB_KEY,
  BASIS_ID_REGISTRATION_URL,
  GET_BASIS_ID_SIGNATURE_URL,
} from "./constants";
import AddressInput from "./components/authentication/addressInput";
import VerificationStatus from "./components/authentication/verificationStatus";
import IDUpload from "./components/authentication/idUpload";
import AddressUpload from "./components/authentication/addressUpload";
import { UploadFile } from "antd/lib/upload/interface";

const Signup = React.lazy(() => import("./components/authentication/signup"));

const SuspenseFallback = (
  <Col className="p-4">
    <Row justify="center" align="middle">
      <Col xs={24} className="text-center">
        <img src={logo} height="40" alt="Student Card Logo" />
      </Col>
    </Row>
    <Row justify="center" align="top" className="mt-3 mb-6">
      <Col xs={24} className="text-center">
        <i>Loading...</i>
      </Col>
    </Row>
  </Col>
);

export interface IUser {
  version?: number;
  userId?: string;
  userHash?: string;
  profile?: IProfile;
  IDPhotoSideOne?: string;
  IDPhotoSideTwo?: string;
  proofOfAddressPhoto?: string;
  allResponses?: string; // JSON String
  verificationStatus?: "approved" | "rejected" | "processing" | "unavailable";
  verificationTimestamp?: string;
  submittedTimestamp?: string;
  raw_IDPhotoSideOne?: UploadFile<any>;
  raw_IDPhotoSideTwo?: UploadFile<any>;
}

export interface IAddress {
  country: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
}

export interface IProfile {
  email: string;
  lastName?: string;
  middleNames?: string;
  firstName?: string;
  dateOfBirth?: string;
  gender?: string;
  address?: IAddress;
  phoneNumber: string;
  nationality: string;
  identityNumber?: string;
  identityDocumentExpiryDate?: string;
  phoneAndEmailVerified?: boolean;
  consentGiven?: boolean;

  raw_phoneCode?: string;
  raw_mobile?: string;
}

const loadState = (): IUser | undefined => {
  //fetch from local storage
  try {
    const serialisedState = localStorage.getItem("state");
    if (serialisedState === null) {
      return undefined;
    }
    return JSON.parse(serialisedState);
  } catch (err) {
    return undefined;
  }
};

const stateVersion = 3;
const saveState = (state: IUser) => {
  try {
    state = { ...state, version: stateVersion };
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("state", serialisedState);
  } catch (err) {}
};

const RootRouter = () => {
  const { push } = useHistory();

  const loadedState = loadState();
  const newState: IUser = { version: 3, verificationStatus: "unavailable" };

  const [user, setUser] = useState<IUser>(
    loadedState && loadedState.version === stateVersion ? loadedState : newState

    // {
    //   version: 2,
    //   profile: {
    //     phoneNumber: '230-52953562',
    //     email: 'ubeidjamaml@sd.com',
    //     nationality: 'MU',
    //     lastName: 'Jamal',
    //     firstName: 'Ubeid',
    //     dateOfBirth: '1992-02-01',
    //     gender: 'male',
    //     identityNumber: '1234',
    //     identityDocumentExpiryDate: '2021-02-02',
    //     phoneAndEmailVerified: true,
    //     consentGiven: true,
    //     address: {
    //       country: 'MU',
    //       city: 'Port Louis',
    //       streetName: 'Strr',
    //       postalCode: '81418',
    //     },
    //     raw_mobile: '52953562',
    //     raw_phoneCode: 'MU+230',
    //   },
    //   proofOfAddressPhoto: 'sd',
    //   submittedTimestamp: 'Sat 01 Aug 2020 16:16:16',
    //   allResponses: JSON.stringify({
    //     baseCheckResponse: {
    //       api_access_token: 'aat-2e96518d-80a9-4723-b7eb-4ca09368603f',
    //       api_refresh_token: 'art-b0824290-b8e9-4e5b-bee0-87081df88f67',
    //       blockchain_read_token: '',
    //       status: 'ok',
    //       user_hash: 'd7014035-4721-4bad-a4be-fef816256fea',
    //       user_id: 1400056,
    //     },
    //     idFileOneResponse: {
    //       status: 'ok',
    //       file: '/passport/46541cb9-680c-439d-b0f4-61040a238621.png',
    //     },
    //     idFileTwoResponse: {
    //       status: 'ok',
    //       file: '/passport/064b47ba-888e-4957-bfe1-f87c0087c648.png',
    //     },
    //     utilityFileResponse: {
    //       status: 'ok',
    //       file: '/slip/181f9895-094e-4ef3-aac2-2e0237a3604b.jpg',
    //     },
    //   }),
    //   userHash: 'd7014035-4721-4bad-a4be-fef816256fea',
    //   userId: '1400056',
    //   verificationStatus: 'approved',
    //   verificationTimestamp: 'Sat 01 Aug 2020 16:20:16',
    // }
  );

  const resetState = () => {
    setUser(newState);
  };

  const setBasicProfile = (profile: IProfile) => {
    setUser((u) => ({
      ...u,
      profile: { ...u.profile, ...profile },
      verificationStatus: "unavailable",
      submittedTimestamp: undefined,
    }));
  };

  const setPhoneAndEmailVerified = () => {
    setUser((u) =>
      u.profile
        ? { ...u, profile: { ...u.profile, phoneAndEmailVerified: true } }
        : u
    );
  };

  const setConsentGiven = () => {
    setUser((u) =>
      u.profile ? { ...u, profile: { ...u.profile, consentGiven: true } } : u
    );
  };

  const updateAddress = (address: IAddress) => {
    setUser((u) =>
      u.profile ? { ...u, profile: { ...u.profile, address: address } } : u
    );
  };

  const updateProfile = (profile: IProfile) => {
    setUser((u) =>
      u.profile ? { ...u, profile: profile } : { ...u, profile: profile }
    );
  };

  const setIDPhotos = (
    sideOne: string,
    sideOneRaw: UploadFile<any>,
    sideTwo: string,
    sideTwoRaw: UploadFile<any>
  ) => {
    setUser((u) => ({
      ...u,
      IDPhotoSideOne: sideOne,
      IDPhotoSideTwo: sideTwo,
      raw_IDPhotoSideOne: sideOneRaw,
      raw_IDPhotoSideTwo: sideTwoRaw,
    }));
  };

  const setProofOfAddressPhoto = (proofPhoto: string) => {
    setUser((u) => ({
      ...u,
      verificationStatus: "unavailable",
      proofOfAddressPhoto: proofPhoto,
    }));
  };

  const pollBasisIDStatus = async (userHash: string) => {
    var signatureResponse = await fetch(GET_BASIS_ID_SIGNATURE_URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userHash: userHash,
      }),
    }).then(function (response) {
      return response.json();
    });
    if (signatureResponse.success) {
      const checkStatus = async () => {
        let profile = await fetch(
          "https://api.basisid.com/users/info/" +
            userHash +
            "/" +
            BASISID_PUB_KEY +
            "/" +
            signatureResponse.signature
        ).then((res) => res.json());

        if (
          profile &&
          (profile.user_status === 11 || profile.user_status === 10)
        ) {
          setUser((u) =>
            u
              ? {
                  ...u,
                  verificationStatus:
                    profile.user_status === 10 ? "approved" : "rejected",
                  verificationTimestamp: moment().format(
                    "ddd DD MMM YYYY HH:mm:ss"
                  ),
                }
              : u
          );
          if (profile.user_status === 11) {
            message.error(
              "Verfication Failed. BasisID did not accept your request.",
              5
            );
          } else {
            message.success(
              "Verfication Successful. BasisID approved your request.",
              5
            );
          }
        } else {
          setTimeout(checkStatus, 60000);
        }
      };
      checkStatus();
    }
  };

  const submitToBasisID = async () => {
    if (
      user.profile &&
      user.profile.address &&
      user.profile.dateOfBirth &&
      user.IDPhotoSideOne &&
      user.IDPhotoSideTwo &&
      user.proofOfAddressPhoto
    ) {
      push("/signup/verificationStatus");
      let request: any = {
        key: BASISID_PUB_KEY,
        first_name: user.profile.firstName,
        last_name: user.profile.lastName,
        email: user.profile.email,
        phone: user.profile.phoneNumber,
        gender:
          user.profile.gender === "male"
            ? 0
            : user.profile.gender === "female"
            ? 1
            : 2,
        birthday_day: user.profile.dateOfBirth.substring(8, 10),
        birthday_month: user.profile.dateOfBirth.substring(5, 7),
        birthday_year: user.profile.dateOfBirth.substring(0, 4),
        country_nationality: user.profile.nationality,
        country_residence: user.profile.address.country,
        city: user.profile.address.city,
        address: user.profile.address.streetName,
        zip: user.profile.address.postalCode,
      };
      if (user.profile.middleNames) {
        request["middle_name"] = user.profile.middleNames;
      }
      // console.log('creating form data for', user);
      var response = await fetch(BASIS_ID_REGISTRATION_URL, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          baseCheckRequest: request,
          idFileOnePath: user.IDPhotoSideOne,
          idFileTwoPath: user.IDPhotoSideTwo,
          utilityPath: user.proofOfAddressPhoto,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .catch((e) => {
          console.log(e);
        });

      if (
        response &&
        response.success &&
        response.baseCheckResponse.status === "ok"
      ) {
        setUser((u) => ({
          ...u,
          userHash: response.baseCheckResponse.user_hash,
          userId: response.baseCheckResponse.user_id,
          allResponses: JSON.stringify(response),
          verificationStatus: "processing",
          submittedTimestamp: moment().format("ddd DD MMM YYYY HH:mm:ss"),
        }));
        // TODO check if one upload failed
        message.info("Request submitted. Review is in process");
        pollBasisIDStatus(response.baseCheckResponse.user_hash);
      } else {
        message.error("Request rejected. Please contact an admin", 5);
        setUser((u) => ({
          ...u,
          verificationStatus: "rejected",
          verificationTimestamp: moment().format("ddd DD MMM YYYY HH:mm:ss"),
        }));
      }
    } else {
      console.log(
        "Not submitting request to basisid as all info in not available."
      );
    }
  };

  useEffect(() => {
    if (user.verificationStatus === "unavailable" && user.proofOfAddressPhoto) {
      console.log("submitting to basis id");
      submitToBasisID();
    }
    // eslint-disable-next-line
  }, [user.proofOfAddressPhoto]); // call submit when proofOfAddressPhoto changes (i.e. is set)

  useEffect(() => {
    if (user && user.verificationStatus === "processing" && user.userHash) {
      pollBasisIDStatus(user.userHash);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      saveState(user);
    }
  }, [user]);

  return (
    <Layout className="app">
      <Suspense fallback={SuspenseFallback}>
        <Switch>
          <Redirect exact from="/" to="/signup" />
          <Route exact path="/signup">
            <Signup
              setBasicProfile={setBasicProfile}
              userProfile={user.profile}
              resetState={resetState}
            />
          </Route>
          <Route exact path="/signup/contactVerification">
            {user.profile ? (
              <ContactVerification
                setPhoneAndEmailVerified={setPhoneAndEmailVerified}
              />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/consent">
            {user.profile ? (
              <DataCollectionConsent setConsentGiven={setConsentGiven} />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/profile">
            {user.profile ? (
              <ProfileDetailsInput
                profile={user.profile}
                updateProfile={updateProfile}
              />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/address">
            {user.profile ? (
              <AddressInput
                profile={user.profile}
                updateAddress={updateAddress}
              />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/idUpload">
            {user.profile ? (
              <IDUpload setIDPhotos={setIDPhotos} user={user} />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/addressUpload">
            {user.profile ? (
              <AddressUpload setProofOfAddressPhoto={setProofOfAddressPhoto} />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Route exact path="/signup/verificationStatus">
            {user.profile && user.proofOfAddressPhoto ? (
              <VerificationStatus user={user} />
            ) : (
              <Redirect to="signup" />
            )}
          </Route>
          <Redirect from="/" to={"/signup"} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default RootRouter;
